// api接口
export let baseUrl = 'https://scrm-api.lmmot.com/'
// h5链接
export let h5Url = 'https://scrm-guide.lmmot.com/'
// cdn链接
export let cdnUrl = 'https://we-customer-spring-1301506715.cos.ap-guangzhou.myqcloud.com/applet/'
// #ifdef MP-WEIXIN
const {
	miniProgram: { envVersion }
} = uni.getAccountInfoSync()

switch (envVersion) {
	// 线上环境
	case 'release':
		baseUrl = 'https://scrm-api.lmmot.com/'
		h5Url = 'https://scrm-guide.lmmot.com/'
		break
	// 体验环境、本地环境
	default:
		//测试
		baseUrl = 'https://scrm-api-test.lmmot.com/'
		h5Url = 'https://scrm-guide-test.lmmot.com/'
}
// #endif

// #ifdef H5
baseUrl = import.meta.env.VITE_APP_API_BASEURL
h5Url = import.meta.env.VITE_H5_BASEURL
// #endif
